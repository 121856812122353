import React from "react";
import styled from "styled-components";
import { useGlobalContext } from "../context";

const Pagination = () => {
  const { goToPage, page, totalPages } = useGlobalContext();
  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

  const nextPage = () => {
    if (page < totalPages){ 
      window.scrollTo(0, 0);
      goToPage(page + 1)
    };
  };

  const prevPage = () => {
    if (page > 1) {
      window.scrollTo(0, 0);
      goToPage(page - 1)
    }
  };

  return (
    <Container>
      <ul className="pagination justify-content-center">
        <li className="page-item" onClick={()=>prevPage()} style={{ display: page === pageNumbers[0] ? "none" : "inline-block"}}>
          <p className="page-link">
            &lt;&lt;
          </p>
        </li>
        {pageNumbers.map((pgNumber) => (
          <li
            key={pgNumber}
            className={`page-item ${page == pgNumber ? "active" : ""} `}
            onClick={() => {
              goToPage(pgNumber);
              window.scroll(0,0)
            }}
          >
            <p
              className="page-link"
            >
              {pgNumber}
            </p>
          </li>
        ))}
        <li className="page-item" onClick={()=>nextPage()} style={{ display: page === totalPages ? "none" : "inline-block"}}>
          <p className="page-link">
            &gt;&gt;
          </p>
        </li>
      </ul>
    </Container>
  );
};

const Container = styled.nav`
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    li{
      width: 50px;
      height: 50px;
      text-decoration: none;
      list-style-type: none;
    }
    p{
      text-align: center;
    }
  }
  .page-item {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: var(--mainBlack);
    background-color: #fff;
    border: 1px solid #dee2e6;
  }
  .page-item.active {
    z-index: 1;
    color: #fff;
    background-color: var(--mainBlack);
    border-color: var(--mainBlack);
  }

 
`;

export default Pagination;










// const goToPage = (pageNum) => {
//         if (pageRefs[pageNum] || pageNum === 1) {
//             fetchPage(pageNum);
//             console.log("pn", pageNum)
//         }
//         console.log("pn", pageNum)
//     };

// This function is in a context and I passed it as props in a component.
// In the component, const nextPage = () => {
//     if (currentPage < totalPages){ 
//       // setCurrentPage(currentPage + 1)
//       goToPage(currentPage + 1)

//     };
//   };

// It takes time before it starts outputing the result and it's constant 2. It doesn't go past 2. How do I ensure that immediately I click it, it gives an answer and it keeps increasing as long as I keep pressing it
