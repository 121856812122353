import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import { useGlobalContext } from "../../context";

const Featured = ({ id }) => {
  const { initialBlogs } = useGlobalContext();
  const navigate = useNavigate();

  const handleClick = (currentId) => {
    navigate(`/news/${currentId}`);
  };

  const refineDate = (dateStr) => {
    const date = moment(dateStr, "ddd MMM DD YYYY");
  
    return date.format("MMMM D, YYYY");
  }

  return (
    <>
      {initialBlogs.length > 1 ? 
        <FeaturedContainer>
          <div className="featured__wrapper">
            <h2>More Posts</h2>
            <div className="flexbox">
              {initialBlogs
                .filter((post) => post.id !== id)
                .slice(0, 2)
                .map((post) => (
                  <div className="box" onClick={() => handleClick(post.id)}>
                    <img 
                    src={post.imgUrl}
                    alt="" />
                    <h3
                      dangerouslySetInnerHTML={{ __html: post.title }}
                    ></h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: post.description.slice(0, 120) + "... ",
                      }}
                    ></p>
                    <p className="orange">{refineDate(post.createdAt)}.</p>
                  </div>
                ))}
            </div>
          </div>
        </FeaturedContainer> :
        <></>
      }
    </>
    
  );
};

const FeaturedContainer = styled.section`
  padding: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 24px;
  }
  .flexbox {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 24px;
  }
  .box {
    padding: 20px;
    h3 {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: var(--mainPurple);
    }
    img {
      width: 100%;
      object-fit: contain;
      margin-bottom: 24px;
    }
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      margin: 5px 0;
      color: var(--fontColor);
    }
    p.orange {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: var(--mainOrange);
    }
    :hover {
      cursor: pointer;
      transform: translateY(-2px);
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
        0 10px 10px rgba(0, 0, 0, 0.22);
    }
  }

  @media (min-width: 650px) {
    img{
        object-fit: cover !important;
        height: 300px;
    }
  }

  @media (min-width: 900px) {
    .featured__wrapper {
      width: 856px;
    }
    img{
        height: 200px;
    }
    .flexbox {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 32px;
      .box {
        width: 416px;
      }
    }
  }
`;

export default Featured;
