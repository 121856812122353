import React from "react";
import Form from "./Form";
import Header from "./Header";

const index = () => {
  return (
      <>
        <Header />
        <Form />
      </>
  );
};

export default index;
