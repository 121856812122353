import React, { useEffect } from "react";
import "./styles.css";
import { useGlobalContext } from "./context";
import styled from "styled-components";
import Home from "./screens/Home";
import Merchandise from "./screens/Merchandise";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from "./screens/About";
import Blog from "./screens/Blog";
import BlogPost from "./screens/Blog/Post";
import Contact from "./screens/Contact";
import Wrapper from "./components/Wrapper";
import Default from "./components/Default";
import AOS from "aos";
import "aos/dist/aos.css";
import Spinner from "./components/Spinner";


function App() {
  const { initialBlogs } = useGlobalContext();

  useEffect(() => {
    AOS.init({duration:1500});
    AOS.refresh();
  }, []);

  if (initialBlogs.length === 0) {
    return (
      <Container>
        <div>
          <Spinner /> 
          <h1>STANDAGE</h1>
        </div>
      </Container>
    )
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Wrapper />}>
          <Route element={<Home />} index />
          <Route path="about" element={<About />} />
          <Route path="merchandise" element={<Merchandise />} />
          <Route path="news" element={<Blog />} />
          <Route path="/news/:id" element={<BlogPost />} />
          <Route path="contact-us" element={<Contact />} />
          <Route path="*" element={<Default />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;


const Container = styled.div`
  background-color: #1A1A1A;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  
  div {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }
  h1 {
    font-weight: 800;
    font-size: 32px;
    line-height: 48px;
    color: var(--mainOrange);
    width: 100%
  }

  @media (max-width: 767px) {
    h1 {
      font-size: 24px;
      line-height: 36px;
    }
  }
`
