import React from "react";
import Header from "./Header";
import Products from "./Products";

const index = () => {
  return (
    <>
      <Header />
      <Products />
    </>
  );
};

export default index;
