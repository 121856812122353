import React, { useState, useEffect, useContext } from "react";
import { db } from "../config/firebase";
import { collection, limit, onSnapshot, orderBy, query, startAfter } from 'firebase/firestore';

const AppContext = React.createContext();

export const AppProvider = ({ children })=>{
    const [initialBlogs, setInitialBlogs] = useState([])
    const [blogs, setBlogs] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0)
    const [pageRefs, setPageRefs] = useState({});
    const [loading, setLoading] = useState(false);

    const ITEMS_PER_PAGE = 4

    useEffect(()=>{
        fetchPage(1);
        getTotalDocs();

        const unsub = onSnapshot(
            query(collection(db, "blogs"), orderBy("timeStamp", "desc"), limit(4)),
            (snapshot) => {
                let list = [];
                snapshot.docs.forEach((doc)=>{
                    list.push({ id: doc.id, ...doc.data() })
                });
                setInitialBlogs(list)
            }, (error) => {
                console.log(error)
            }
        );

        return ()=> {
            unsub()
        }
    }, [])

    const fetchPage = (pageNum) => {
        setLoading(true);
        let q;

        if (pageNum === 1) {
            q = query(collection(db, "blogs"), orderBy("timeStamp", "desc"), limit(ITEMS_PER_PAGE));
        } else if (pageRefs[pageNum - 1]) {
            q = query(collection(db, "blogs"), orderBy("timeStamp", "desc"), startAfter(pageRefs[pageNum - 1]), limit(ITEMS_PER_PAGE));
        } else {
            setLoading(false);
            return;
        }

        const unsub = onSnapshot(q, (snapshot) => {
            let list = [];
            snapshot.docs.forEach((doc) => {
                list.push({ id: doc.id, ...doc.data() });
            });
            setBlogs(list);
            setPage(pageNum);
            setPageRefs(prevRefs => ({ ...prevRefs, [pageNum]: snapshot.docs[snapshot.docs.length - 1] }));
            setLoading(false);
        }, (error) => {
            console.log(error);
            setLoading(false);
        });

        return ()=> {
            unsub()
        }
    }

    const getTotalDocs = ()=> {
        const unsubscribeTotalDocs = onSnapshot(
            collection(db, "blogs"),
            (snapshot) => {
                const count = snapshot.size;
                setTotalPages(Math.ceil(count / ITEMS_PER_PAGE));
            },
            (error) => {
                console.log(error);
            }
        );

        return () => {
            unsubscribeTotalDocs();
        };
    }

    const goToPage = (pageNum) => {
        if (pageRefs[pageNum - 1] || pageNum === 1) {
            setPage(pageNum);
            fetchPage(pageNum);
        }
    };

    return(
        <AppContext.Provider value={{
            initialBlogs, blogs, loading, page, totalPages, goToPage
        }}>
            {children}
        </AppContext.Provider>
    );
}

export const useGlobalContext = ()=>{
    return useContext(AppContext);
}